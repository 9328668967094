import { Caption, Image, useStyles } from '@archipro-design/aria';
import { Link, useSearchParams } from '@remix-run/react';
import * as S from './LoginPage.style';
import type { LoginSignUpProps } from '../component/login-component/LoginSignUp';
import LoginSignUp from '../component/login-component/LoginSignUp';
import { PolicyLink } from '../component/policy-link/PolicyLink';
import { useConfigContext } from '@archipro-website/config/bindings/react';
import { LogoAnimated } from '../../../../../rocco/src/components/logo-animated/LogoAnimated';
interface LoginPageProps {
    login: Partial<LoginSignUpProps>;
}

export const LoginPage = (props?: LoginPageProps) => {
    const config = useConfigContext();
    const { css } = useStyles();

    const [searchParams] = useSearchParams();

    const skipPostSignUpRedirect =
        !!props?.login?.skipPostSignUpRedirect ||
        searchParams.get('skipPostSignUp') === '1';

    const redirect = searchParams.get('redirect');

    return (
        <div className={css(S.PageWrapper)}>
            <Image
                src={`${config.assetsBaseUrl}/assets/website/images/ui/home-hero-image-mini.png`}
                width={1920}
                height={1080}
                layout="fill"
                alt="login"
                objectFit="cover"
            />
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.15)',
                    width: '100%',
                    height: '100%',
                }}
            ></div>
            <div className={css(S.ContentWrap)}>
                <div className={css(S.Content)}>
                    <Link className={css(S.LogoWrapper)} to="/">
                        <LogoAnimated size={4} mode="disabled" color="light" />
                    </Link>

                    <div className={css(S.LoginWrapper)}>
                        <LoginSignUp
                            {...props?.login}
                            authSource="loginPage"
                            skipPostSignUpRedirect={skipPostSignUpRedirect}
                            redirect={redirect || ''}
                        />
                    </div>
                </div>

                <div className={css(S.Links)}>
                    <PolicyLink as="a" />
                    <Caption variant="02" as={Link} to="/become-a-pro">
                        ArchiPro for Business
                    </Caption>
                    <Caption variant="02" as="a" href="/contact-us">
                        Contact Us
                    </Caption>
                </div>
            </div>
        </div>
    );
};
