/* eslint-disable max-lines */
import { Button, Flex } from '@archipro-design/aria';
import { Subheader } from '@archipro-design/aria';
import { useStyles } from '@archipro-design/aria';
import { useTheme } from '@archipro-design/aria';
import { Caption } from '@archipro-design/aria';
import { Divider } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';
import { useControllableValue } from 'ahooks';
import { useEnquirySession } from '~/modules/enquiry/hook/use-enquiry-session';
import { usePublicUser } from '../../hook/use-public-user';
import ForgotPasswordForm from '../forgot-password/ForgotPasswordForm';
import LoginSignUpForm from '../login-signup-form/LoginSignUpForm';
import SocialLogin from '../social-login/SocialLogin';
import * as S from './LoginSignUp.style';
import { useLoginFlow, type LoginFlow } from '../../hook/use-login-flow';
import type { PublicUser } from '~/routes/remix-api.user.public-user';
import { CloseLine } from '@archipro-design/icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from '@remix-run/react';
import { PolicyLink } from '../policy-link/PolicyLink';
import { useUser } from '@modules/user/hook/use-user';
import { useBypassForcedSocial } from '../../hook/use-bypass-forced-social';
import { useRootData } from '~/modules/root';
import type { AuthSource } from '../../type';

export interface LoginSignUpProps {
    defaultFlow?: LoginFlow;
    defaultPublicUser?: PublicUser;
    skipPostSignUpRedirect?: boolean;
    welcomeMessage?: string;
    authSource: AuthSource;
    redirect?: string;
}

const LoginSignUp = (props: LoginSignUpProps) => {
    const {
        defaultFlow,
        welcomeMessage,
        defaultPublicUser,
        skipPostSignUpRedirect,
        authSource,
        redirect,
    } = props;
    const user = useUser();
    const theme = useTheme();

    const [flow, setFlow] = useControllableValue(props, {
        defaultValue: defaultFlow,
        defaultValuePropName: 'defaultFlow',
    });

    const { enquirySession } = useEnquirySession();
    const { publicUser, handleUserExists } = usePublicUser(
        authSource,
        defaultPublicUser
    );
    const { css } = useStyles({ flow });
    const { title } = useLoginFlow(flow);

    const { env } = useRootData();

    const { bypassForcedSocial } = useBypassForcedSocial(env.stage);
    const [useEmail, setUseEmail] = useState(false);
    const hideEmailAndButton =
        publicUser?.socialLoginOptions.length === 1 &&
        !useEmail &&
        !bypassForcedSocial;

    const changeFlow = useCallback(
        (newFlow: LoginFlow) => {
            // If we came in for the post-enquiry flow
            if (defaultFlow === 'post-enquiry-login' && newFlow === 'login') {
                setFlow(defaultFlow);
            }
            if (
                defaultFlow === 'post-enquiry-sign-up' &&
                newFlow === 'sign-up'
            ) {
                setFlow(defaultFlow);
            } else {
                setFlow(newFlow);
            }
        },
        [defaultFlow, setFlow]
    );

    /**
     * Side Effect:
     * After the target public user changes (email address)
     * Change the login flow to match the options available to the user
     */
    useEffect(() => {
        if (publicUser && user.__typename === 'Guest') {
            const newFlow: LoginFlow =
                publicUser.type === 'existing' ? 'login' : 'sign-up';
            changeFlow(newFlow);
        }
    }, [changeFlow, publicUser, user.__typename]);

    const renderWelcomeMessage = useMemo(() => {
        if (welcomeMessage) {
            return welcomeMessage;
        }
        if (defaultPublicUser?.type === 'new' && enquirySession?.name) {
            return `Welcome, ${enquirySession.name}`;
        }
        if (publicUser?.type === 'existing' && publicUser?.firstName) {
            return `Welcome back, ${publicUser.firstName}`;
        } else {
            return 'Welcome to ArchiPro';
        }
    }, [
        defaultPublicUser?.type,
        enquirySession?.name,
        publicUser?.firstName,
        publicUser?.type,
        welcomeMessage,
    ]);

    const renderFooterContent = () => {
        if (!flow || flow === 'login' || flow === 'post-enquiry-login') {
            return (
                <Caption
                    id="forgot-password-button"
                    link
                    variant="05-alt"
                    onClick={() => {
                        changeFlow('forgot-password');
                    }}
                    variables={{
                        captionColor: theme.siteVariables.colors.charcoal[250],
                    }}
                    tabIndex={0} // Need to have relativeTarget on loginForm blur events
                >
                    Forgot password?
                </Caption>
            );
        }

        return (
            <div className={css(S.FormLink)}>
                <Caption variant="05-alt" inline>
                    By signing up, you have read and agree to our
                </Caption>
                <PolicyLink
                    variant="05-alt"
                    inline={true}
                    as="a"
                    link={true}
                    target="_blank"
                    design={{ paddingLeft: pxToRem(3) }}
                    style={{ textDecoration: 'underline' }}
                />
            </div>
        );
    };

    const hasSocialLoginOptions = !!publicUser?.socialLoginOptions?.length;

    const hideFooter = flow === 'post-enquiry-login' && hasSocialLoginOptions;

    if (flow === 'forgot-password' || flow === 'forgot-password-completed') {
        return (
            <Flex column className={css(S.LoginWrapper)}>
                <Flex className={css(S.LoginHeader)}>
                    <Caption variant="04" weight="medium">
                        {title}
                    </Caption>
                    <Button
                        onClick={() => changeFlow('login')}
                        icon={<CloseLine />}
                        size={30}
                        variant="text"
                        color="dark"
                        variables={{
                            buttonPadding: pxToRem(4),
                        }}
                    />
                </Flex>
                <ForgotPasswordForm
                    flow={flow}
                    onBack={() => {
                        changeFlow('login');
                    }}
                    onSuccess={() => {
                        changeFlow('forgot-password-completed');
                    }}
                />
            </Flex>
        );
    }

    const closeButtonLink = decodeURIComponent(redirect || '/');

    const linkButtonStyle = {
        textDecoration: 'underline',
        cursor: 'pointer',
        border: 'none',
        background: 'none',
        padding: 0,
        margin: 0,
        color: 'inherit',
    };

    return (
        <Flex column className={css(S.LoginWrapper)}>
            <Flex className={css(S.LoginHeader)}>
                <Caption variant="04" weight="medium">
                    {title}
                </Caption>
                <Button
                    as={Link}
                    to={closeButtonLink}
                    icon={<CloseLine />}
                    size={30}
                    variant="text"
                    color="dark"
                    variables={{
                        buttonPadding: pxToRem(4),
                    }}
                />
            </Flex>
            <Subheader className={css(S.Subheader)} variant="02">
                {renderWelcomeMessage}
            </Subheader>

            {welcomeMessage && (
                <Caption className={css(S.LoginMessage)} variant="03-alt">
                    {welcomeMessage}
                </Caption>
            )}

            {flow === 'post-enquiry-sign-up' && (
                <Caption variant="03-alt">
                    Great, you’ve made your first enquiry.&nbsp;
                    <br />
                    Connect your social media or enter a password to complete
                    your account sign up.
                </Caption>
            )}

            <SocialLogin
                loginOptions={publicUser?.socialLoginOptions}
                preOptionText={hideEmailAndButton ? 'Continue with' : undefined}
                useEmail={useEmail}
                authSource={authSource}
                skipPostSignUp={!!skipPostSignUpRedirect}
            />
            {hideEmailAndButton ? (
                <div style={{ width: '100%' }}>
                    <Caption
                        variant="04"
                        variables={{
                            color: theme.siteVariables.colors.charcoal[100],
                        }}
                        design={{ marginTop: pxToRem(12) }}
                    >
                        <div>
                            Not you? &nbsp;
                            <button
                                onClick={() => {
                                    setUseEmail(true);
                                }}
                                style={linkButtonStyle}
                            >
                                Use another account
                            </button>
                        </div>
                        <div style={{ marginTop: pxToRem(8) }}>
                            Or, &nbsp;
                            <button
                                onClick={() => {
                                    setUseEmail(true);
                                }}
                                style={linkButtonStyle}
                            >
                                log in with password
                            </button>
                        </div>
                    </Caption>
                </div>
            ) : (
                <>
                    <Divider
                        color="grey"
                        content={'or'}
                        size={1}
                        design={{
                            width: '100%',
                            marginTop: pxToRem(14),
                        }}
                        variables={{
                            dividerPrimaryColor:
                                theme.siteVariables.colors.charcoal[100],
                            dividerPadding: pxToRem(24),
                            textFontSize: pxToRem(12),
                        }}
                    />

                    <LoginSignUpForm
                        flow={flow}
                        onEmailBlur={handleUserExists}
                        onResetPassword={(e) => {
                            changeFlow('forgot-password');
                            e.preventDefault(); // Prevent following <a href> to legacy password reset
                        }}
                        defaultPublicUser={publicUser}
                        skipPostSignUp={skipPostSignUpRedirect}
                        authSource={authSource}
                        redirect={redirect}
                    />
                </>
            )}

            {!hideFooter && (
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: pxToRem(14),
                    }}
                >
                    {renderFooterContent()}
                </div>
            )}
        </Flex>
    );
};

export default LoginSignUp;
