import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, getScrollbarStyle } from '@archipro-design/aria';
import { getDvhStyles } from '~/modules/design-board/util';

export const PageWrapper: StyleRule = ({ theme }) => ({
    ...getDvhStyles('height', '', 100),
    [theme.screen.tablet]: {
        width: '100%',
        backgroundColor: theme.siteVariables.colors.primitive.black,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export const ContentWrap: StyleRule = ({ theme }) => ({
    [theme.screen.tablet]: {
        position: 'absolute',
        inset: '0',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: pxToRem(24),
    },
});

export const Content: StyleRule = ({ theme }) => ({
    width: '100%',
    flexGrow: 1,
    [theme.screen.tablet]: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
});

export const LogoWrapper: StyleRule = ({ theme }) => ({
    display: 'none',
    color: 'white',
    [theme.screen.tablet]: {
        paddingLeft: pxToRem(128),
        display: 'flex',
        flexBasis: '55%',
        justifyContent: 'center',
        alignSelf: 'center',
    },
});

export const LoginWrapper: StyleRule = ({ theme }) => ({
    [theme.screen.tablet]: {
        ...getDvhStyles('maxHeight', '', 92),
        overflowY: 'auto',
        ...getScrollbarStyle(theme, '0'),
    },
});

export const Links: StyleRule = ({ theme }) => ({
    display: 'none',
    [theme.screen.tablet]: {
        display: 'flex',
        paddingBottom: pxToRem(24),
        gap: pxToRem(60),
        justifyContent: 'center',
        width: '100%',
    },
    '& a': {
        color: theme.siteVariables.colors.white['000'],
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});
